
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[...category]",
      function () {
        return require("private-next-pages/[...category].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[...category]"])
      });
    }
  